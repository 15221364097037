'use strict'

angular.module('cb.appointment-service', [])
	.factory('AppointmentService', ["ApiService", function (ApiService) {

		var AppointmentService = {
			addHolidayBooking: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/createHoliday';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)
				}
			},
			MakeAppointment: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/add';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)
				}
			},

			GetHolidayRequests: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/holiday';

					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)
				}
			},
			UpdateHolidayRequest: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/holiday/update';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)
				}
			},

			getAppointmentsForCurrentUser: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/user';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)
				}
			},

			getAppointmentsForUsers: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/users';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)
				}
			},
			GetSalesOrderAppointments: function (id) {
				try {
					var url = '/webapi/api/v1/appointment/salesorderappointments/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e)

				}
			},
			UpdateSalesOrderAppointments: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/salesorderappointments/update';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)

				}
			},
			UpdateAppointmentBooking: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/user/update';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e)

				}
			},

			GetContractConsoleAppointments: function (data) {
				try {
					var url = '/webapi/api/v1/appointment/GetContractConsoleAppointments';
					return ApiService.promisePost(url, data);
				}
				catch (e) {
					console.error('Error!', e);
				}
			}

		};

		return AppointmentService;
	}]);
